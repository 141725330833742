<template>
  <div class="container">
    <div class="page-title">
      <i class="el-icon-back" @click="back"  style="cursor:pointer"></i>
      <span style="padding-left: 8px;">订单详情</span>
    </div>
    <div class="content">
      <div class="content-top">
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 110px;text-align: right;">成为客户时间:</div>
            <div>{{detail.createTime | rTime}}</div>
          </div>
        </div>
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 110px;text-align: right;">客户账号:</div>
            <el-tooltip class="item" effect="dark" content="点击复制" placement="right">
              <div style="margin-right: 20px;width: 80px;" @click="copyAccount(detail.aliAccount)">{{detail.aliAccount}}</div>
            </el-tooltip>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 120px;">餐饮类型</div>
            <el-select v-model="detail.eateryType" placeholder="请选择" style="width: 150px;">
              <el-option
                v-for="item in eateryOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 120px;">小瓶白酒种类</div>
            <el-select v-model="detail.wineType" placeholder="请选择" style="width: 150px;">
              <el-option
                v-for="item in wineNameOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 110px;text-align: right;">微信号:</div>
            <el-tooltip class="item" effect="dark" content="点击复制" placement="right">
              <div style="margin-right: 20px;width: 80px;" @click="copyWechat(detail.wechat)">{{detail.wechat}}</div>
            </el-tooltip>
          </div>
          <div class="date-box">
            <div class="label" style="width: 120px;text-align: right;">消费金额:</div>
            <div style="width: 150px;">{{detail.amountSum}}</div>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 120px;">是否有营销需求</div>
            <el-select v-model="marketingFlag" placeholder="请选择" style="width: 150px;">
              <el-option
                v-for="item in marketOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 110px;text-align: right;">购买次数:</div>
            <div style="margin-right: 20px;width: 80px;">{{detail.buyCount}}</div>
          </div>
        </div>
        <div style="display: flex;justify-content: center;">
          <el-button type="primary" @click="save">保存</el-button>
        </div>
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 110px;text-align: right;">标签:</div>
            <div>优质用户</div>
          </div>
        </div>
      </div>
      <div class="content-bottom">
        <div style="font-size: 16px;margin-bottom: 10px;">订单操作记录</div>
        <div style="margin: 0 auto;border: 1px solid #000;padding: 10px;width: 900px;flex:1;overflow:auto;display: flex;flex-direction: column;">
          <el-table
            :show-header="false"
            :data="tableData"
            stripe
            style="width:100%;overflow:auto;"
            v-loading="listLoading"
          >
            <el-table-column
              prop="date"
             >
              <template slot-scope="scope">
                <div class="record" v-if="scope.row.entity === 'TASK_FLOW'">
                  <div style="padding-right: 5px;">{{scope.row.statusName}}:</div>
                  <div v-if="scope.row.timeout === 'Y'">
                    <div v-if="scope.row.remark">{{scope.row.remark}}</div>
                    <div v-else style="color: #409EFF;" @click="handle(scope.row.id)">待处理</div>
                  </div>
                  <div v-else>{{scope.row.timeout}}</div>
                </div>
                <div class="record" v-else>
                  <div style="width: 150px;">{{scope.row.createTime | rTime}}</div>
                  <div>{{scope.row.statusName}}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[5, 10, 20, 40]"
            layout="total, prev, pager, next, jumper"
            background
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  const cityOptions = ['待处理', '已初稿修改中', '缺图', '已退款', '等资料', '等修改中', '设计不满意','加急'];
  export default {
    data() {
      return {
        accountId: '',
        orderId: '',
        detail: {},  //详情
        options: [],
        value: '',
        eateryOptions: [],
        areaNameOptions: [],
        wineNameOptions: [],
        marketingFlag: true,
        marketOptions: [//营销需求
          {
            value: true,
            label: '是'
          },
          {
            value: false,
            label: '否'
          }
        ],
        tableData: [],
        total: 0,
        pageSize: 5,
        currentPage:1,
        listLoading: false,
      };
    },
    activated () {
      console.log('this.$route.query.accountId',this.$route.query.accountId)
      this.accountId = this.$route.query.accountId;
      this.orderId = this.$route.query.orderId;
      this.queryDetail();
      this.$http.post('/dictionary/findChildListByParentId/1', { parentId: 1 }, { headers: {
          token: window.sessionStorage.manageToken
        }})
        .then(res => {
          console.log('res====',res)
          if (res.data.code === 'ok') {
            console.log('resdatadata====eateryOptions',res.data.data)
            this.eateryOptions = res.data.data;
            // this.$router.push({ path: "/layout" });
          } else {
            this.$message.error(res.data.data)
          }
        });
      this.queryDetailRecord(this.currentPage,this.pageSize);
      this.$http.post('/dictionary/findChildListByParentId/3', { parentId: 3 }, { headers: {
          token: window.sessionStorage.manageToken
        }})
        .then(res => {
          console.log('res====',res)
          if (res.data.code === 'ok') {
            console.log('resdatadata====wineNameOptions',res.data.data)
            this.wineNameOptions = res.data.data;
          } else {
            this.$message.error(res.data.data)
          }
        });
    },
    methods: {
      save(){
        console.log('保存');
        const data = {
          id: this.accountId,
          eateryType: this.detail.eateryType,
          // areaType: this.detail.areaType,
          wineType: this.detail.wineType,
          marketingFlag: this.marketingFlag
        }
        this.$http.post(`/plan/updateResturantById`, data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====detail',res.data.data)
              this.$message({
                showClose: true,
                message: res.data.data,
                type: 'success'
              });
              this.queryDetail();
              this.queryDetailRecord(this.currentPage,this.pageSize);
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      queryDetail(){
        this.$http.post(`/plan/findResturantByAccountId/${this.accountId}`, {}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.detail = res.data.data;
              this.marketFlag = this.detail.marketingFlag ? true : false;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },

      copyAccount(aliAccount){
        let _this = this;
        this.$copyText(aliAccount).then(function (e) {
          _this.$message({
            showClose: true,
            message: "复制成功",
            type: "success"
          });
        }, function (e) {
          _this.$message({
            showClose: true,
            message: "复制失败，请手动复制",
            type: "error"
          });
        })
      },
      copyWechat(wechat){
        let _this = this;
        this.$copyText(wechat).then(function (e) {
          _this.$message({
            showClose: true,
            message: "复制成功",
            type: "success"
          });
        }, function (e) {
          _this.$message({
            showClose: true,
            message: "复制失败，请手动复制",
            type: "error"
          });
        })
      },
      queryDetailRecord(currentPage,pageSize){
        this.$http.post(`/plan/findOrderDetailByPage/${currentPage}/${pageSize}`, { orderId:this.orderId }, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data);
              this.tableData = res.data.data.list || [];
              this.total = res.data.data.total;
              this.tableData.forEach((item,index,array)=>{
                console.log('item,index',item,index)
                //执行代码
                if(item.entity === "TASK_FLOW"){
                  const visitTime = new Date(item.visitTime.substr(0, 19)).getTime();
                  const nowDate = new Date().getTime();
                  var time = (visitTime - nowDate)/1000;
                  if(time <= 0){
                    //待处理或已处理
                    this.$set(this.tableData[index],'timeout','Y');
                  }else{
                    //剩余多少天
                    if(null != time && "" != time) {
                      if(time > 60 && time < 60 * 60) {
                        time = parseInt(time / 60.0) + "分钟";
                      } else if(time >= 60 * 60 && time < 60 * 60 * 24) {
                        time = parseInt(time / 3600.0) + "小时" + parseInt((parseFloat(time / 3600.0) -
                          parseInt(time / 3600.0)) * 60) + "分钟";
                      } else if(time >= 60 * 60 * 24) {
                        time = parseInt(time / 3600.0 / 24) + "天" + parseInt((parseFloat(time / 3600.0 / 24) -
                          parseInt(time / 3600.0 / 24)) * 24) + "小时" + parseInt((parseFloat(time / 3600.0) -
                          parseInt(time / 3600.0)) * 60) + "分钟";
                      } else {
                        time = parseInt(time) + "秒";
                      }
                    }
                    this.$set(this.tableData[index],'timeout',time);
                  }
                  console.log('this.tableData=',this.tableData);
                }
              })
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.queryDetailRecord(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.queryDetailRecord(this.currentPage,this.pageSize);
      },
      handle(id) {
        this.$prompt('', '请输入处理内容', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          this.$http.post(`/plan/updateOrderDetailRemark`, {remark:value,id}, { headers: {
              token: window.sessionStorage.manageToken
            }})
            .then(res => {
              console.log('res====',res)
              console.log('resdata====',res.data)
              //  console.log("输出response.data", res.data);
              // this.shopOptions = res.data;
              if (res.data.code === 'ok') {
                console.log('resdatadata====',res.data.data)
                this.$message({
                  type: 'success',
                  message: '处理成功'
                });
                this.queryDetail();
                this.queryDetailRecord(this.currentPage,this.pageSize);
                // this.$router.push({ path: "/layout" });
              } else {
                this.$message.error(res.data.data)
              }
            });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
      },
      back(){
        this.$router.go(-1);
      }
    },
    filters: {
      rTime(data) {
        if(!data) return
        var dt = new Date(data);
        var y = dt.getFullYear();
        var m = (dt.getMonth()+1).toString().padStart(2,"0");
        var d = dt.getDate().toString().padStart(2,"0");
        var h = dt.getHours().toString().padStart(2,"0");
        var mm = dt.getMinutes().toString().padStart(2,"0");
        var s = dt.getSeconds().toString().padStart(2,"0");
        return `${y}-${m}-${d} ${h}:${mm}:${s}`;
      },
    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    overflow: auto;
    .content-top {
      display: flex;
      margin-top: 10px;
      /*justify-content: flex-start;*/
      flex-direction: column;
      /*background-color: red;*/
    }
    .first-line {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    .label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /*text-align: right;*/
      font-size: 16px;
      color: #000000;
      padding-right: 10px;
    }
    .date-box {
      display: flex;
      align-items: center;
      padding-left: 12px;
    }
    .third-line {
      margin-left: 20px;
      margin-top: 10px;
    }
  }
  .record {
    display: flex;
  }
  .content-bottom {
    flex: 1;
    overflow: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  /deep/ .el-table td, .el-table th {
    padding: 3px 0;
  }
  /deep/ .el-table__body {
    width: 100%;
  }
</style>
